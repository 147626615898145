<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-04-18 09:52:56
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-06-09 09:58:49
-->
<template>
    <div class="box-wrap">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">抽奖设置</div>
        </div>
        <div class="case-wrap">
            <div class="add-wrap">
                <el-button type="primary"
                    @click="isAddScenarios = true">添加活动</el-button>
            </div>
            <el-table v-loading="loading" border :data="tableData" style="width: 100%;">
                <el-table-column align="center" label="抽奖背景图" prop="date">
                    <template slot-scope="scope">
                        <img class="image" :src="scope.row.image" alt="">
                    </template>
                </el-table-column>
                <el-table-column align="center" label="活动名称" prop="name"></el-table-column>
                <el-table-column align="center" label="活动描述" prop="remark"></el-table-column>
                <el-table-column align="center" label="创建时间" prop="create_time"></el-table-column>
                <el-table-column align="center" label="操作" width="300">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary"
                        @click="handleLook(scope.row)">活动详情</el-button>
                        <el-button size="mini"
                        @click="handleEdit(scope.row)">编辑</el-button>
                        <el-button size="mini" type="danger"
                        @click="handleDelete(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 添加 -->
        <el-dialog
            :before-close="handleCloseScenarios" 
            :close-on-click-modal="false"
            :visible.sync="isAddScenarios" 
            custom-class="dialog-wrap">
            <div v-if="editId<0" class="title">添加活动</div>
            <div v-else class="title">编辑活动</div>
            <div class="content-wrap" v-loading="addLoading">
                <el-form :model="scenariosForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
                    <el-form-item label="活动名称" prop="name">
                        <el-input v-model="scenariosForm.name" placeholder="请输入活动名称"></el-input>
                    </el-form-item>
                    <el-form-item label="活动描述" prop="remark">
                        <el-input v-model="scenariosForm.remark" placeholder="请输入活动描述"></el-input>
                    </el-form-item>
                    <el-form-item label="抽奖背景图" prop="image">
                        <div class="upload-wrap">
                            <aliyun-upload 
                                :limitSize="10" :limit="1" :isShopTip="false" :sizePx="true"
                                :btnDisplay="image.length<1" listType="picture-card"
                                v-model="image" :validWidth="1920" :validHeight="1080">
                            </aliyun-upload>
                            <div v-if="image.length>0" class="fileList-box">
                                <div @click="delImageList" class="delFile">x</div>
                                <el-image fit="contain" class="pic"
                                    :preview-src-list="image" :src="image[0]"/>
                            </div>
                            <div>上传图片大小不超过10M ，分辨率为1920 * 1080</div>
                        </div>
                    </el-form-item>
                    <div class="btn-wrap">
                        <el-button v-if="editId<0" :loading="btnLoading" type="primary" @click="submitForm">立即创建</el-button>
                        <el-button v-else :loading="btnLoading" type="primary" @click="submitFormEdit">编 辑</el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
import AliyunUpload from "@/components/AliyunUpload.vue";
export default {
    components:{
        AliyunUpload,
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'sweepstakesSetup',
            isAddScenarios:false,
            scenariosForm: {
                name: '',
                remark: '',
                image: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                ],
                remark: [
                    { required: true, message: '请输入活动描述', trigger: 'blur' }
                ],
                image: [
                    { required: true, message: '请上传背景图片', trigger: 'blur' },
                ],
            },
            loading: false,
            btnLoading: false,
            addLoading: false,
            image: [],
            background: [],
            editId: -1,
            tableData: [],
        }
    },
    mounted () {
        this.getList();
    },
    watch: {
        image: {
            handler(newval, oldval) {
                if(newval.length > 0){
                    this.scenariosForm.image = newval[0]
                }
            },
            deep: true
        }
    },
    methods: {
        // 获取列表数据
        getList(){
            this.loading = true;
            common.connect("/rafflev1/activity/index",{},(res)=>{
                this.tableData = res.data.list;
                this.loading = false;
            });
        },
        // 添加活动
        submitForm(){
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                this.btnLoading = true;
                common.connect("/rafflev1/activity/store",this.scenariosForm,(res)=>{
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.getList();
                    this.image = [];
                    this.$refs.ruleForm.resetFields();
                    this.btnLoading = false;
                    this.isAddScenarios = false;
                });
                } else {
                console.log('error submit!!');
                return false;
                }
            });
        },
        // 编辑活动
        submitFormEdit(){
             this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                this.scenariosForm.id = this.editId
                this.btnLoading = true;
                common.connect("/rafflev1/activity/update",this.scenariosForm,(res)=>{
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.getList();
                    this.image = [];
                    this.editId = -1;
                    this.$refs.ruleForm.resetFields();
                    this.btnLoading = false;
                    this.isAddScenarios = false;
                });
                } else {
                console.log('error submit!!');
                return false;
                }
            });
        },
        // 删除
        handleDelete(row){
            this.$confirm('此操作将永久删除该活动, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect("/rafflev1/activity/delete",{id: row.id},(res)=>{
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.getList();
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 编辑
        handleEdit(row){
            this.editId = row.id;
            this.isAddScenarios = true;
            this.addLoading = true
            common.connect("/rafflev1/activity/edit",{id: row.id},(res)=>{
                this.image[0] = res.data.image;
                this.scenariosForm.name = res.data.name;
                this.scenariosForm.image = res.data.image;
                this.scenariosForm.remark = res.data.remark;
                this.addLoading = false
            });
        },
        // 查看名单
        handleLook(row){
            this.$router.push("/eventDetails");
            localStorage.setItem('infoId',row.id);
        },
        delImageList(){
            this.image.splice(0,1)
        },
        // 关闭弹窗
        handleCloseScenarios(){
            this.isAddScenarios = false;
        },
    },
}
</script>

<style lang='scss'>
.box-wrap{
    background: rgb(240, 242, 245);
    // padding: 0 25px;
    height: 100vh;
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 15px 25px;
        .tag{
            width: 4px;
            height: 20px;
            background: #409EFF;
            border-radius: 10px;
        }
        .title{
            font-size: 18px;
            font-weight: bold;
            padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px 20px 5px 20px;
        border-radius: 6px;
        margin: 0 25px;
        .add-wrap{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 15px;
        }
        .image{
            height: 40px;
            vertical-align: bottom;
        }
    }
    .dialog-wrap{
        width: 750px;
        .title{
            font-size: 16px;
            color: #000;
            padding-bottom: 10px;
            margin-left: 20px;
            margin-top: -5px;
        }
        .content-wrap{
            max-height: 80vh;
            overflow-y: auto;
            padding-top: 20px;
            .el-input{
                width: 600px
            }
            .btn-wrap{
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 20px;
            }
            .upload-wrap-content{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
            .upload-wrap{
                padding-top: 2px;
                .fileList-box{
                    width: 100px;
                    height: auto;
                    position: relative;
                    .delFile{
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        cursor: pointer;
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        border: 1px #ddd solid;
                        background: rgba(100, 100, 100, 0.5);
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 99;
                    }
                    .pic{
                        width: 100%;
                    }
                }
            }
        }
    }
    .dialog-client-wrap{
        width: 750px;
        padding: 0 20px 20px 20px;
        .title{
            font-size: 16px;
            color: #000;
            padding-bottom: 15px;
            margin-top: -5px;
        }
        .btn{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 15px;
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
}
</style>
